export default (config) => {
  const { db } = config;

  const configsRef = db.collection("configs");

  const paperTradingRef = db
    .collection("paperTrading")
    .doc(`${config.symbol.toLowerCase()}-${config.version}`);

  const interactiveBrokersRef = db
    .collection("interactiveBrokers")
    .doc(`${config.symbol.toLowerCase()}-${config.version}`);

  const scopeRef = config.paperTrading ? paperTradingRef : interactiveBrokersRef;

  const signalRef = db
    .collection("signals")
    .doc(`${config.symbol.toLowerCase()}-${config.version}`);

  const manualRef = db
    .collection("manual")
    .doc(`NQ-1`);

  const debugRef = db
    .collection("debug")
    .doc(`NQ-1`);

  const chartConfigRef = db
    .collection("charts")
    .doc("config");

  const chartOneRef = db
    .collection("charts")
    .doc("CHART-1");

  const chartTwoRef = db
    .collection("charts")
    .doc("CHART-2");

  const paperBalanceRef = db.collection("balance").doc("usd")
  const cashBalanceRef = db.collection("balance").doc("usd")

  const ordersRef = db.collection("orders");
  const lastOpenOrderRef = ordersRef.doc("lastOpenOrder");
  const autoCompleteRef = db.collection("autoComplete");
  const positionsRef = db.collection("info").doc("positions");
  const accountInfoRef = db.collection("info").doc("accounts");

  const openPositionsRef = db.collection("openPositions");
  const controlPanelRef = db
    .collection("controlPanels")
    .doc(`${config.symbol.toLowerCase()}-${config.version}`);

  const logsRef =  db.collection("logs");//.doc('all').collection('logs');

  const mainConfigRef = db
    .collection("controlPanels")
    .doc(`main-config`);

  return {
    configsRef,
    signalRef,
    manualRef,
    chartConfigRef,
    chartOneRef,
    chartTwoRef,
    paperBalanceRef,
    cashBalanceRef,

    ordersRef,
    lastOpenOrderRef,
    openPositionsRef,

    controlPanelRef,
    logsRef,
    debugRef,
    mainConfigRef,
    autoCompleteRef,
    accountInfoRef,
    positionsRef
  }
}